import { React } from "react";
import { useFetchBlog } from "../hooks/useFetchBlog";
import { Spinner, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const url = "https://3dctrl.com/webfrontend/api/query/";
const page = "blog";
let alltags = [];

//filter tags function
let handleTags = (e) => {
  let allButtons = document.querySelectorAll(`.filterButton`);
  allButtons.forEach((i) => {
    i.classList.remove("active");
  });
  e.target.classList.toggle("active");
  let buttonFilter = e.target.value;

  let elements = document.querySelectorAll(`.postCard`);
  elements.forEach((i) => {
    let dataAttribute = i.getAttribute("datatag");
    if (buttonFilter === "All") {
      i.style.display = "flex";
    } else if (dataAttribute.includes(buttonFilter)) {
      i.style.display = "flex";
    } else {
      i.style.display = "none";
    }
  });
};

let tags = (tag) => {
  return (
    <button className="filterButton" key={tag} onClick={handleTags} value={tag}>
      {tag}
    </button>
  );
};

let items = (item) => {
  let imgurl = "";
  if (item.pagecontent[0].content.image) {
    imgurl = `${item.url}/${item.pagecontent[0].content.image[0]}`;
  } else {
    imgurl = process.env.PUBLIC_URL + "/images/logo.svg";
  }
  let postExceprt = "";
  if (item.pagecontent[1].content.text) {
    postExceprt = item.pagecontent[1].content.text;
  }
  postExceprt = postExceprt.slice(0, 100);

  return (
    <Col
      xs="12"
      md="4"
      key={item.title}
      className={`postCard All`}
      datatag={item.content.tags}
    >
      <Link to={`/resources/blog/${item.title}`}>
        <img src={imgurl} alt=""></img>
        <div className="postTag">{item.content.tags}</div>
        <div className="postTitle">{item.title}</div>
        <div className="postExcerpt">{parse(postExceprt)}</div>
      </Link>
    </Col>
  );
};

const Blog = () => {
  const { loading, dataBlog } = useFetchBlog(url, page);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col className="mt-5 mb-5 text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  } else {
    //retrieve posts
    let posts = dataBlog.data.result.data;
    //console.log(posts);

    //array of tags
    posts.forEach((i) => {
      let tag = i.content.tags;

      //check if tag contains coma, i.e. more than 1 tag
      if (tag.indexOf(",") > -1) {
        //tag contains coma, split tags
        let arrayTags = tag.split(",");
        arrayTags.forEach((item) => {
          item = item.trimStart();
          if (!alltags.includes(item)) {
            alltags = [...alltags, item];
          }
        });
      } else {
        if (!alltags.includes(tag)) {
          alltags = [...alltags, tag];
        }
      }
    });

    return (
      <section className="blogPage">
        <Container>
          <Row key="1" className="filterRow">
            <div className="filterTitle">Filter: </div>
            <button
              className="active filterButton"
              key="0"
              onClick={handleTags}
              value="All"
            >
              All
            </button>
            {alltags.map(tags)}
          </Row>
          <Row key="2">{posts.map(items)}</Row>
        </Container>
      </section>
    );
  }
};

export default Blog;
