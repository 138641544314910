import React from "react";
import { useFetchBlog } from "../hooks/useFetchBlog";
import { Spinner, Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import RelatedBlogItems from "../components/RelatedBlogItems";

const url = "https://3dctrl.com/webfrontend/api/query/";
const page = "blog";

let subitem = (pageurl, item) => {
  //console.log(item);

  if (item.type === "text") {
    return <div key={item.id}>{parse(item.content.text)}</div>;
  } else if (item.type === "image") {
    let imgurl = `${pageurl}/${item.content.image[0]}`;
    return <img key={item.id} src={imgurl} alt=""></img>;
  } else if (item.type === "list") {
    return <div key={item.id}>{parse(item.content.text)}</div>;
  } else if (item.type === "heading") {
    return <h3 key={item.id}>{parse(item.content.text)}</h3>;
  } else if (item.type === "video") {
    //sanitize video url and pop excess
    let videourl = item.content.url;
    videourl = videourl.split("v=").pop();
    let iframeurl = `https://www.youtube.com/embed/${videourl}`;

    return (
      <div key={item.id}>
        <iframe
          width="100%"
          height="348"
          src={iframeurl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  }
};

let items = (pagetitle, item) => {
  if (pagetitle.pagetitle === item.title) {
    let tag = item.content.tags;
    let pageurl = item.url;
    return (
      <Row key={item.title} className="blogPostBody">
        <Col xs="12" md="4">
          <div className="tag">{tag}</div>
          <h1 className="blogTitle">
            {/*3Dctrl: <br></br>*/}
            {pagetitle.pagetitle}
          </h1>
          {/*<div className="blogShare">
            <div className="blogShareItemTitle">Share</div>
            <div className="blogShareItem">Facebook</div>
            <div className="blogShareItem">Twitter</div>
            <div className="blogShareItem">LinkedIn</div>
            <div className="blogShareItem">Email</div>
          </div>*/}
        </Col>
        <Col xs="12" md="8" className="blogPostContent">
          {item.pagecontent.map(subitem.bind(pageurl, pageurl))}
        </Col>

        {/*<div className="blogShareMobile">
          <div className="blogShareItemTitle">Share</div>
          <div className="blogShareItem">Facebook</div>
          <div className="blogShareItem">Twitter</div>
          <div className="blogShareItem">LinkedIn</div>
          <div className="blogShareItem">Email</div>
        </div>*/}
      </Row>
    );
  }
};

const Blogitem = () => {
  const pagetitle = useParams();
  const { loading, dataBlog } = useFetchBlog(url, page);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col className="mt-5 mb-5 text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  } else {
    let posts = dataBlog.data.result.data;

    return (
      <>
        <section className="blogItem">
          <Container>{posts.map(items.bind(pagetitle, pagetitle))}</Container>
        </section>
        <RelatedBlogItems />
      </>
    );
  }
};

export default Blogitem;
