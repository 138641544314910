import React from "react";
import HerobannerShort from "../components/HerobannerShort";
import { useFetchFaq } from "../hooks/useFetchFaq";
import { Spinner, Container, Row, Col, Accordion } from "react-bootstrap";
import parse from "html-react-parser";

const url = "https://3dctrl.com/webfrontend/api/query/";
const page = "faq";

let items = (item) => {
  return (
    <Accordion.Item
      eventKey={item.columns[0].blocks[0].id}
      key={item.columns[0].blocks[0].id}
    >
      {/*console.log(item.columns[0].blocks[0].id)*/}
      <Accordion.Header>
        {item.columns[0].blocks[0].content.text}
      </Accordion.Header>
      <Accordion.Body>
        {parse(item.columns[0].blocks[1].content.text)}
      </Accordion.Body>
    </Accordion.Item>
  );
};

const Faq = () => {
  const { loading, dataFaq } = useFetchFaq(url, page);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col className="mt-5 mb-5 text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  } else {
    const heroimage = {
      pagetitle: dataFaq.data.result.title,
      imgurl: dataFaq.data.result.image,
      herotitle: dataFaq.data.result.content.headline,
      herosubtitle: dataFaq.data.result.content.subheading,
      ctatext: dataFaq.data.result.content.ctatext,
      ctaurl: dataFaq.data.result.content.ctaurl,
    };
    let faqitems = dataFaq.data.result.faqrepeater;
    // console.log(data);
    //console.log(faqitems);
    return (
      <>
        <HerobannerShort {...heroimage} />
        <section className="FaqPage">
          <Container>
            <Row>
              <Col xs="12" md="4"></Col>
              <Col xs="12" md="8">
                <Accordion
                  flush
                  defaultActiveKey={faqitems[0].columns[0].blocks[0].id}
                >
                  {faqitems.map(items)}
                </Accordion>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
};

export default Faq;
