import React from "react";
import { useFetchBlogRelatedItems } from "../hooks/useFetchBlogRelatedItems";
import { Spinner, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const url = "https://3dctrl.com/webfrontend/api/query/";
const page = "blog";

let items = (item) => {
  let imgurl = `${item.url}/${item.pagecontent[0].content.image[0]}`;
  let postExceprt = item.pagecontent[1].content.text;
  postExceprt = postExceprt.slice(0, 100);

  return (
    <Col
      xs="12"
      md="4"
      key={item.title}
      className={`postCard All ${item.content.tags}`}
    >
      <Link to={`/resources/blog/${item.title}`}>
        <img src={imgurl} alt=""></img>
        <div className="postTag">{item.content.tags}</div>
        <div className="postTitle">{item.title}</div>
        <div className="postExcerpt">{parse(postExceprt)}</div>
      </Link>
    </Col>
  );
};

const RelatedBlogItems = () => {
  const { loading, blogRelatedItems } = useFetchBlogRelatedItems(url, page);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col className="mt-5 mb-5 text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  } else {
    //retrieve posts
    let posts = blogRelatedItems.data.result.data;

    return (
      <section className="blogPage RelatedBlogItems">
        <Container>
          <Row key="0">
            <Col>
              <h3 className="text-center">Related posts</h3>
            </Col>
          </Row>
          <Row key="1">{posts.slice(0, 3).map(items)}</Row>
        </Container>
      </section>
    );
  }
};

export default RelatedBlogItems;
