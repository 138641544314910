import React from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";

document.addEventListener("DOMContentLoaded", function () {
  let el_autohide = document.querySelector(".autohide");

  if (el_autohide) {
    var last_scroll_top = 0;
    window.addEventListener("scroll", function () {
      let scroll_top = window.scrollY;

      if (scroll_top > 70) {
        if (scroll_top < last_scroll_top && scroll_top) {
          el_autohide.classList.remove("scrolled-down");
          el_autohide.classList.add("scrolled-up");
        } else {
          el_autohide.classList.remove("scrolled-up");
          el_autohide.classList.add("scrolled-down");
        }
        last_scroll_top = scroll_top;
      }
    });
  }
});

const Navigation = () => {
  return (
    <>
      <Navbar expand="xl" className="autohide">
        <Container>
          <Navbar.Brand href="/web">
            <img
              src={process.env.PUBLIC_URL + "/images/logo.svg"}
              alt="logo"
              className="logo"
            ></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/web/features">Features</Nav.Link>
              <Nav.Link href="/web/services">Services</Nav.Link>
              <Nav.Link href="/web/about">About Us</Nav.Link>
              <NavDropdown title="Resources" id="nav-dropdown">
                <NavDropdown.Item href="/web/resources/blog">
                  Blog + Case Studies
                </NavDropdown.Item>
                <NavDropdown.Item href="/web/resources/tutorials">
                  Tutorials + Reels
                </NavDropdown.Item>
                <NavDropdown.Item href="/web/resources/faq">
                  FAQ
                </NavDropdown.Item>
              </NavDropdown>
              {/*<Nav.Link href="/web/pricing">Pricing</Nav.Link>*/}
              <Nav.Link href="/web/contact">Contact</Nav.Link>
              <Nav.Link href="/login">Log In</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Navigation;
